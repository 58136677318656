export const USER_TYPE = {
	academy: "academy",
	playground: "playground",
	viewer: "viewer",
	scouting: "scouting",
	player: "player",
};

export const USERS_TYPE = {
	players: "players",
	academies: "academies",
	playgrounds: "playgrounds",
	viewers: "viewers",
	scoutings: "scoutings",
};

export type UserType = (typeof USER_TYPE)[keyof typeof USER_TYPE];
export type UsersType = (typeof USERS_TYPE)[keyof typeof USERS_TYPE];
